import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Aditya Kate </span>
            from <span className="purple"> Maharashtra, India.</span>
            <br />*I am a highly skilled and experienced <b>software engineer</b> with a passion for using technology to solve real-world problems. 
            <br />*I am available for full-time or contract work. I am also <b>open to remote work</b>.
            <br />*Part time <b>Trader and Investor</b>. 
            <br />*Currently diving deeper into Artificial Intelligence and Machine Learning.
            <br />
            <br />
            Apart from coding, some other activities that I love to do :()
          </p>
          <ul>
          <li className="about-activity">
              <ImPointRight /> You can ask me about Investments and Finance
            </li>
            <li className="about-activity">
              <ImPointRight /> Caliography
            </li>
            <li className="about-activity">
              <ImPointRight /> Reading Documentries(nitche: Finance)
            </li>
            <li className="about-activity">
              <ImPointRight /> Play a musical instrument (Piano)
            </li>
          </ul>

        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
